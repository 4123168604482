import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Author = () => { 
    return (
    	<div className="flex author">
    		<StaticImage src="../images/author.jpg" className="author--image" alt="Оксана Федосова" placeholder="none" quality={100} />
            <div>
                <p className="author--name">Оксана Федосова</p>
                <p className="author--description">Эксперт по матрасам и другим товарам для сна</p>
            </div>
    	</div>
    );
};

export default Author;