import React from "react"
import styled from "styled-components"

const ShareBlock = styled.div`
  margin-top: 40px;
  text-align: center;

  p {
    font-weight: bold !important;
    font-size: 20px !important;
  }
`

export default function Share() {

	return (
  	<>
    	{/*<div className="max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
				<div className="ya-share2" data-curtain data-limit="5" data-copy="hidden" data-services="telegram,vkontakte,twitter,facebook,odnoklassniki,messenger" />
			</div>*/}
      <ShareBlock>
        <p>Понравилась статья? Поделись с друзьями!</p>
        <div className="ya-share2" data-curtain data-limit="5" data-copy="hidden" data-services="telegram,vkontakte,twitter,facebook,odnoklassniki,messenger" />
      </ShareBlock>
  	</>
	)
}

