import React, { useState } from 'react';

const Accordeon = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
    {title && (
    <div className="accordeon-custom__item" itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <div className={isActive ? 'accordeon-custom__item-title active cursor-pointer' : 'accordeon-custom__item-title cursor-pointer'} itemProp="name" role="button" tabIndex="0" onClick={() => setIsActive(!isActive)} onKeyDown={() => setIsActive(!isActive)}>{title}</div>
      <div className={isActive ? 'accordeon-custom__item-text show' : 'accordeon-custom__item-text'} itemProp="acceptedAnswer" itemScope itemType="https://schema.org/Answer">
        <div className="font-[merriweather]" itemProp="text" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      
    </div>
    )}
    </>
  );
};

export default Accordeon;