import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Компоненты
import Menu from "../components/menu.js"
import Seo from "../components/seo.js"
import Share from "../components/share.js"
import TableOfContent from "../components/table-of-content-raiting-brand.js"
import Faq from "../components/faq.js"
import Otherpages from "../components/otherpages.js"
import Footer from "../components/footer.js"
import Author from "../components/author.js"

// Картинки
import garanty from "../images/icons/garanty-brand.svg"
import price from "../images/icons/price-brand.svg"
import time from "../images/icons/time-brand.svg"

export default function BrandRaiting({data}) {  
  	// Оборачиваем таблицу в div 
	const addDiv = () => {
		const el = document.querySelector('table');
		if(el) { 
			const wrapper = document.createElement('div'); 
			wrapper.classList.add('table-data-content'); 
			el.parentElement.insertBefore(wrapper, el); 
			wrapper.appendChild(el); 
		}
	};
	
	useEffect(()=>{
		addDiv(); // Оборачиваем таблицу в div 
	}, [])

	const updatedAt = data.allContentfulBrandRaiting.edges.map(({ node }) => ( node.updatedAt)) // Получаем дату обновления страницы
	const title = data.allContentfulBrandRaiting.edges.map(({ node }) => ( node.titlePage)) // Получаем заголовок страницы

	const otherArticles = data.allContentfulBrandRaiting.edges.map(({ node }, i) => ( node.otherArticles.map(pages => ( pages )) ))

	const faqs = data.allContentfulBrandRaiting.edges.map(({ node }) => ( node))

	const accordionData = [ // Создаем массив с пунктами FAQ
	  	{
	    	title: faqs[0].faqTitle1 && faqs[0].faqTitle1,
	    	content: faqs[0].faqText1 && faqs[0].faqText1.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle2 && faqs[0].faqTitle2,
	    	content: faqs[0].faqText2 && faqs[0].faqText2.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle3 && faqs[0].faqTitle3,
	    	content: faqs[0].faqText3 && faqs[0].faqText3.childMarkdownRemark.html,
	  	},
	  	{
	    	title: faqs[0].faqTitle4 && faqs[0].faqTitle4,
	    	content: faqs[0].faqText4 && faqs[0].faqText4.childMarkdownRemark.html,
	  	},
	  	{ 
	    	title: faqs[0].faqTitle5 && faqs[0].faqTitle5,
	    	content: faqs[0].faqText5 && faqs[0].faqText5.childMarkdownRemark.html,
	  	},
	]

	const options = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timezone: 'UTC'
	};

	const dateUpdate = new Date(updatedAt[0]).toLocaleString("ru", options)

	return (
		<>	
			{data.allContentfulBrandRaiting.edges.map(({ node }) => (
				<div key={Math.random()}>
					<Seo title={node.title} description={node.description} />
					<Helmet>
						<script type="application/ld+json">{`{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Главная","item": "https://sleepadvisor.ru"},{"@type": "ListItem","position": 2,"name": "Блог","item": "https://sleepadvisor.ru/blog/"},{"@type": "ListItem","position": 3,"name": "${node.titlePage}"}]}`}</script>
						<script type="application/ld+json">{`{"@context":"https://schema.org","@graph":[{"@type":"Article","mainEntityOfPage":{"@type":"WebPage","@id":"https://sleepadvisor.ru/${node.url}/"},"headline":"${node.titlePage}","description":"${node.description}","author":{"@type":"Person","name":"Оксана Федосова","knowsAbout":"Эксперт по матрасам и другим товарам для сна","url":"https://sleepadvisor.ru/author.jpg"},"publisher":{"@type":"Organization","name":"SleepAdvisor","logo":{"@type":"ImageObject","url":"https://sleepadvisor.ru/logo.svg"}},"datePublished":"${node.createdAt}","dateModified":"${node.updatedAt}","potentialAction":{"@type":"ReadAction","target":{"@type":"EntryPoint","urlTemplate":"https://sleepadvisor.ru/${node.url}/"}}}]}`}</script>
					</Helmet>
				</div>
			))}
			<Menu />
			<div className="hero all-page">
				<div className="container max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
					<div className="breadcrumb-block">    
						<div className="breadcrumbs">
					        <nav aria-label="breadcrumb">
					        	<ol>
					                <li className="breadcrumb-item"><a href="/">Главная</a></li>
					                <li className="breadcrumb-item active" aria-current="page">{title}</li>
				                </ol>
				            </nav>
					    </div>
					</div>
					<h1>{title}</h1>
					<p className="date-modified font-[merriweather]">Обновлено {dateUpdate.replace(' г.', '')}</p>
				</div>
			</div>
			<section className="content py-12 max-w-[540px] md:max-w-[750px] mx-auto px-[15px]">
				<div className="container">
					{data.allContentfulBrandRaiting.edges.map(({ node }) => (
						<div key={node.id} className="text-lg font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.minitext.childMarkdownRemark.html }}/>
					))}
					{data.allContentfulBrandRaiting.edges.map(({ node }) => (
						<TableOfContent postsToRender={node} />
					))}
					<div className="about-item texting">
						<h2 id="top">Топ-10 производителей матрасов {(new Date().getFullYear())} года</h2>
						{data.allContentfulBrandRaiting.edges.map(({ node }, i) => (
							<ol className="top-list" key={node.id}>
								{node.brand.sort((a, b) => (a.positionRaiting > b.positionRaiting ? 1 : -1)).map(brand =>
									<li key={brand.id}>
										{/*<a href={matres.affilateLink ? matres.affilateLink : matres.linkPrice} target="_blank" rel="noopener nofollow noreferrer" onClick={() => {window.ym(62038639,'reachGoal','button_price')}}>{matres.brand ? (matres.brand.name +' '+ matres.name) : (matres.name)}</a>*/}
										<a href={brand.linkPrice} target="_blank" rel="noopener nofollow noreferrer sponsored" onClick={() => {window.ym(62038639,'reachGoal','button_price')}}>{brand.name}</a>
									</li>
								)}
							</ol>
						))}
						{data.allContentfulBrandRaiting.edges.map(({ node }) => (
							<div key={node.id} className="text-lg font-[merriweather] mt-12" dangerouslySetInnerHTML={{ __html: node.textRaiting.childMarkdownRemark.html }}/>
						))}
				    
						{data.allContentfulBrandRaiting.edges.map(({ node }, i) => (
							node.brand.sort((a, b) => (a.positionRaiting > b.positionRaiting ? 1 : -1)).map(brand =>
					        <div className="item-brand" key={Math.random()}>
					            <div className="item-brand__title">
					                <h3 className="font-merriweather">{1+(i++) + '. ' + brand.name}</h3>
					                <div className="logo">
					                	<GatsbyImage image={getImage(brand.image)} alt={brand.name} />
					                </div>
					            </div>
					            <div id={i} class="marketWidgetBlog" data-text={'матрасы '+brand.name} />
					            {node.aboutBrand1 && node.aboutBrand1.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand1.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand2 && node.aboutBrand2.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand2.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand3 && node.aboutBrand3.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand3.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand4 && node.aboutBrand4.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand4.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand5 && node.aboutBrand5.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand5.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand6 && node.aboutBrand6.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand6.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand7 && node.aboutBrand7.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand7.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand8 && node.aboutBrand8.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand8.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand9 && node.aboutBrand9.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand9.childMarkdownRemark.html }}/>
					            )}
					            {node.aboutBrand10 && node.aboutBrand10.childMarkdownRemark.headings[0].value === brand.name && (
					            	<div key={node.id} className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: node.aboutBrand10.childMarkdownRemark.html }}/>
					            )}
					            <div className="item-brand__characteristic">
					            	<div className="item">
					            		<div className="item__image"><img src={time} alt="Жесткость" loading="lazy" width="40" height="73" /></div>
					            		<div>
						            		<p className="item__title">Срок изготовления</p>
						            		<p className="item__data">{brand.productionTimeMattress}</p>
					            		</div>
					            	</div>
					            	<div className="item">
					            		<div className="item__image"><img src={price} alt="Жесткость" loading="lazy" width="40" height="73" /></div>
					            		<div>
						            		<p className="item__title">Ценовой диапазон</p>
						            		<p className="item__data">{brand.priceMattress}</p>
					            		</div>
					            	</div>
					            	<div className="item">
					            		<div className="item__image"><img src={garanty} alt="Жесткость" loading="lazy" width="40" height="73" /></div>
					            		<div>
						            		<p className="item__title">Гарантия</p>
						            		<p className="item__data">{brand.guaranteeMattress}</p>
					            		</div>
					            	</div>
					            </div>
					            <div className="item-brand__plus-minus font-merriweather">
					            	<div className="item">
					            		<p className="title">Преимущества:</p>
					            		<div className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: brand.plusMattress.childMarkdownRemark.html }}/>
					            	</div>
					            	<div className="item">
					            		<p className="title">Недостатки:</p>
					            		<div className="item-brand__text font-[merriweather]" dangerouslySetInnerHTML={{ __html: brand.minusMattress.childMarkdownRemark.html }}/>
					            	</div>
					            </div>
					            {brand.linkBrandPage && <div className="item-brand__link">
					            	<a href={'/' + brand.linkBrandPage + '/'}>
					            		<button className="button button_primary mt-10 mx-auto">Рейтинг матрасов {brand.name}</button>
					            	</a> 							
					            </div>}					        
					        </div>
				        )))}
					</div>
					{data.allContentfulBrandRaiting.edges.map(({ node }) => (
						<div key={node.id} className="text_content text-lg font-[merriweather] mt-12" dangerouslySetInnerHTML={{ __html: node.text.childMarkdownRemark.html }}/>
					))}
                	<Share />
                	<Author />
				</div>
			</section>
			{faqs[0].faqTitle1 && (<section className="faq py-12 max-w-[540px] md:max-w-[750px] mx-auto px-4">
				<div className="container">
					<h2 id="faq">Вопрос–Ответ</h2>
					<div className="accordeon-custom" itemScope itemType="https://schema.org/FAQPage">
						<div className="accordeon-custom__items">
							{accordionData.map(({ title, content }) => (
								<Faq key={Math.random()} title={title} content={content} />
							))}
						</div>
					</div>
				</div>
			</section>)}
			<Otherpages otherArticles={otherArticles[0]} titlePage="рейтинги" />
            <Footer />
	    </>
    )
}

export const query = graphql`
	query PagesBrandRaiting($slug: String!) {
		allContentfulBrandRaiting(filter: { url: { eq: $slug } }) {
		    edges {
		      	node {
		      		id
		      		url
	          		titlePage
	          		title
	          		description
	          		updatedAt
	          		createdAt
	          		minitext {
	          			childMarkdownRemark {
		            		html
		        		}
	          		}
	          		textRaiting {
	          			childMarkdownRemark {
		            		html
		            		headings(depth: h2) {
				                value
				                id
				            }
		        		}
	          		}
	          		text {
	          			childMarkdownRemark {
		            		html
		            		headings(depth: h2) {
				                value
				                id
				            }
		        		}
	          		}
					faqTitle1
			        faqTitle2
			        faqTitle3
			        faqTitle4
			        faqTitle5
			        faqText1 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText2 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText3 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText4 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        faqText5 {
			          	childMarkdownRemark {
		            		html
		        		}
			        }
			        otherArticles {
			        	__typename
            			... on ContentfulPagesMattress {
            				id
			          		url
			          		titlePage
            			}
			        }
			        aboutBrand1 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand2 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand3 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand4 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand5 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand6 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand7 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand8 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand9 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        aboutBrand10 {
			          	childMarkdownRemark {
			          		html
			            	headings {
			              		value
			            	}
			          	}
			        }
			        brand {
        				__typename
        				... on ContentfulBrand {
        					id
    						name
    						linkBrandPage
    						positionRaiting
    						productionTimeMattress
    						priceMattress
    						guaranteeMattress
    						linkPrice
    						image {
					            gatsbyImageData(
					              	layout: FIXED
					              	quality: 100
					            )
					        }
					        plusMattress {
					        	childMarkdownRemark {
					          		html
					          	}
					        }
					        minusMattress {
					        	childMarkdownRemark {
					          		html
					          	}
					        }
        				}
    				}
		      	}
		    }
		 }
	}
`