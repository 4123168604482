import React from "react"


export default function pages({ otherArticles, titlePage }) {
  return (
    <section className="additionalResources py-8 max-w-[540px] md:max-w-[750px] mx-auto px-4">
		<div className="container">
			<h3>Другие популярные {titlePage}</h3>
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
				{otherArticles.map((post, index) => (
					<div key={index}>
						<a href={'/'+post.url+'/'}>{post.titlePage}</a>
					</div>
				))}
			</div>
		</div>
	</section>
  )
}