import React, { useEffect, useState } from "react"
// import ym from 'react-yandex-metrika';

const TableOfContent = ({ postsToRender, titlePage }) => { 
  const [scrollTableOfContent, setScrollTableOfContent] = useState(false)
  const [array,setArray] = useState([])
  const [scrollImg, setScrollImg] = useState(false)
  const [allH2, setAllH2] = useState()

  // Показываем "быстрые ссылки" при скролле
  const toggleVisibleTableOfContent = () => {
    const el = document.querySelectorAll('.table-of-contents')[0];
    const topOfObjToStick = el.offsetTop+350;
    const scrolled = document.documentElement.scrollTop+250;
    // const faq = document.querySelectorAll('.faq')[0].offsetTop-500;
    const additionalResources = document.querySelectorAll('.additionalResources')[0].offsetTop-500;

    if (scrolled > topOfObjToStick){
      setScrollTableOfContent(true)
      setScrollImg(true)
    } 
    else if (scrolled <= topOfObjToStick){
      setScrollTableOfContent(false)
      setScrollImg(false)
    }
    if (scrolled > additionalResources) {
      setScrollTableOfContent(false)
      setScrollImg(false)
    }

    document.querySelectorAll('h2').forEach((el, i) => { // Выделяем активный пункт меню в "быстрых ссылках""
     if(el.offsetTop <= scrolled) {
       document.querySelectorAll('.table-of-contents.scroll p').forEach((el) => {
         if(el.classList.contains('active')) {
           el.classList.remove('active');
         }
       })
       document.querySelectorAll('.table-of-contents.scroll li')[i].querySelector('p').classList.add('active');
     }
    })
  };

  useEffect(()=>{
    document.addEventListener('scroll', toggleVisibleTableOfContent); // Показываем "быстрые ссылки" при скролле
    setAllH2(document.querySelectorAll('h2').length)
  }, [])

  return (
    <>
      <div className="table-of-contents">
        <p className="table-of-contents__title font-[merriweather]">Быстрые ссылки</p>
        <ul>
          <li>
            <p className="cursor-pointer" 
                onClick={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#top');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }} 
                onKeyPress={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#top');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }}>Топ-10 производителей матрасов {(new Date().getFullYear())} года
            </p>
          </li>
          {postsToRender.textRaiting.childMarkdownRemark.headings.map( item  => (
            <li key={Math.random()}>
              <p className="cursor-pointer" onClick={(e) => {
                e.preventDefault();
                const elementToView = document.getElementById(item.id);
                window.scrollTo({top: elementToView.offsetTop-150});
              }}>{item.value}
              </p>
            </li>
          ))}
          {postsToRender.text.childMarkdownRemark.headings.map( item  => (
            <li key={Math.random()}>
              <p className="cursor-pointer" onClick={(e) => {
                e.preventDefault();
                const elementToView = document.getElementById(item.id);
                window.scrollTo({top: elementToView.offsetTop-150});
              }}>{item.value}
              </p>
            </li>
          ))}
          <li>
            <p className="cursor-pointer" 
                onClick={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#faq');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }} 
                onKeyPress={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#faq');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }}>Вопрос-Ответ
            </p>
          </li>
        </ul>
      </div>
      <div className={scrollTableOfContent ? (allH2 >= 7 ? 'table-of-contents scroll visible more' : 'table-of-contents scroll visible') : ('table-of-contents scroll not-visible')}>
        <p className="table-of-contents__title font-[merriweather]">Быстрые ссылки</p>
        <ul>
          <li>
            <p className="cursor-pointer" 
                onClick={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#top');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }} 
                onKeyPress={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#top');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }}>Топ-10 производителей матрасов {(new Date().getFullYear())} года
            </p>
          </li>
          {postsToRender.textRaiting.childMarkdownRemark.headings.map( item  => (
            <li key={Math.random()}>
              <p className="cursor-pointer" onClick={(e) => {
                e.preventDefault();
                const elementToView = document.getElementById(item.id);
                window.scrollTo({top: elementToView.offsetTop-150});
              }}>{item.value}
              </p>
            </li>
          ))}
          {postsToRender.text.childMarkdownRemark.headings.map( item  => (
            <li key={Math.random()}>
              <p className="cursor-pointer" onClick={(e) => {
                e.preventDefault();
                const elementToView = document.getElementById(item.id);
                window.scrollTo({top: elementToView.offsetTop-150});
              }}>{item.value}
              </p>
            </li>
          ))}
          <li>
            <p className="cursor-pointer" 
                onClick={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#faq');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }} 
                onKeyPress={(e) => {
                  e.preventDefault();
                  const elementToView = document.querySelector('#faq');
                  window.scrollTo({top: elementToView.offsetTop-150});
                }}>Вопрос-Ответ
            </p>
          </li>
        </ul>
      </div>
      {/*<div id="yandex_rtb_R-A-690338-12" className="left-block" />*/}
    </>
);};

export default TableOfContent;